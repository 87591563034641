import React from 'react'
import { Routes } from '@constants'
import Button from '@atoms/Button'

const SideHelp = () => {
  return (
    <div className="section-trial">
      <div className="section-sticky space-y-1">
        <div className="space-y-6">
          <div className="flex flex-col space-y-2">
            <div className="text-sm md:text-sm text-gray-700 leading-snug md:leading-relaxed">
              Need help?
            </div>
            <Button
              type="submit"
              className="w-4/5"
              onClick={() =>
                // eslint-disable-next-line no-undef
                customerly.open()
              }
            >
              Chat with us
            </Button>
          </div>

          <div className="flex flex-col space-y-2">
            <div className="text-sm md:text-sm text-gray-700 leading-snug md:leading-relaxed">
              Product demo?
            </div>
            <a href={Routes.CALENDY} target="_blank" rel="noopener noreferrer">
              <Button type="submit" className="w-4/5">
                Pick a date
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideHelp
