import React from 'react'
import get from 'lodash/get'
import { graphql, Link } from 'gatsby'
import { Config, Routes, Styles } from '@constants'
import TableOfContents from '@components/TableOfContent'
import Layout from '@components/Layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import SideHelp from '@components/custom/SideHelp'
import { GatsbyImage } from 'gatsby-plugin-image'
import HelmetHelper from '@components/HelmetHelper'
import { getDescriptionSubstring } from '@utils'
import getOptions from './getOptions'

const HelpPost = (props) => {
  const post = get(props, 'data.contentfulHelpPost')
  const category = get(post, 'category.0')

  const options = getOptions(post.content.references)
  const bodyRaw = JSON.parse(post.content.raw)
  const metaDescription = getDescriptionSubstring(post.description)

  const header = (
    <div className="max-w-screen-sm mx-auto">
      <div className="flex items-center">
        <div className="text-sm sm:text-md text-gray-700 mt-2 mb-1">
          <div className="flex items-center gap-2">
            <Link className="text-link" to={Routes.HELP.ALL}>
              All categories
            </Link>
            <span className="text-gray-500">/</span>
            <Link to={Routes.HELP.category(category)} className="text-link">
              <div className="capitalize">{category.replace('-', ' ')}</div>
            </Link>
          </div>
        </div>
      </div>
      <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold leading-normal my-5">
        {post.title}
      </h1>
      <div className="flex items-center gap-3">
        <GatsbyImage
          className="h-10 w-10 rounded-full ring-1 ring-slate-100"
          alt={post.author.name}
          title={post.author.name}
          image={post.author.image.gatsbyImageData}
        />
        <div>
          <div className="text-xs sm:text-sx leading-relaxed">
            <span className="text-gray-500">Posted by </span>
            <span className="text-gray-800">{post.author.name}</span>
          </div>
          <div className="text-xs sm:text-sx text-gray-500 leading-relaxed">
            Updated on {post.date}
          </div>
        </div>
      </div>
    </div>
  )

  const body = (
    <div className="section-content section-help max-w-screen-sm mx-auto w-full lg:-mt-4 py-6 md:py-0">
      {!!post.content && documentToReactComponents(bodyRaw, options)}
    </div>
  )

  return (
    <Layout
      disableLinks
      disableDefaultImage
      disableStickyNavbar
      customContainer={Styles.blogContainer}
    >
      <HelmetHelper
        title={`${post.title} | Help Center - ${Config.NAME}`}
        description={metaDescription}
      />
      <div className="md:pt-10 py-10 lg:px-6">
        {header}
        <div className="lg:flex py-10">
          <TableOfContents body={bodyRaw} />
          {body}
          <SideHelp />
        </div>
      </div>
    </Layout>
  )
}

export default HelpPost

export const pageQuery = graphql`
  query HelpPostBySlug($slug: String!) {
    contentfulHelpPost(slug: { eq: $slug }) {
      slug
      title
      category
      description
      date(formatString: "MMMM Do, YYYY")
      author {
        name
        title
        company
        image {
          gatsbyImageData(height: 50, width: 50)
        }
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            id
            contentful_id
            title
            gatsbyImageData(
              placeholder: BLURRED
              resizingBehavior: SCALE
              layout: FULL_WIDTH
            )
          }

          ... on ContentfulHelpPost {
            contentful_id
            id
            category
            slug
          }
        }
      }
    }
  }
`
